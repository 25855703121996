
export const TodoShower = ({ todos, completeTask }) => {
    return (
        <>
        {todos.map((item) => (
            <div>
                <div className="todo-header">{item.header}</div>
                {item.tasks.map((task) => {
                    return (
                    <div key={task.title} className="todo-adder">
                    <input checked={!!task.completed_at} onChange={async () => await completeTask(task)} className="todo-input" type="checkbox"/>
                    <div className="todo-label">{task.title}</div>
                    </div>);
                })}
            </div>
          ))}
          </>
    )
}
