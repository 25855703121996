import { useEffect, useRef, useState } from 'react';
import './App.css';
import './components/todo-adder.css';
import { TodoAdder } from './components/todo-adder';
import { TodoShower } from './components/todo-shower';
import { DateTime } from 'luxon';
import { cloneDeep, groupBy } from 'lodash';
import { v4 } from 'uuid';

async function submitCreateTask(task) {
  await fetch(
    `https://qw7f00rtcf.execute-api.us-east-1.amazonaws.com/writeTask`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ operationType: 'create', task }),
    }
  );
}

async function fetchTasks() {
  const response = await fetch(
    `https://qw7f00rtcf.execute-api.us-east-1.amazonaws.com/writeTask`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ operationType: 'query' }),
    }
  );
  return await response.json();
}

async function submitCompleteTask(task) {
  await fetch(
    `https://qw7f00rtcf.execute-api.us-east-1.amazonaws.com/writeTask`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ operationType: 'complete', id: task.id, completedAt: task.completedAt }),
    }
  );

}
function App() {
  const [todos, setTodos] = useState([{ header: DateTime.now().toLocaleString(DateTime.DATE_SHORT), tasks: [] }]);
  const mounted = useRef(false);

  useEffect(() => {
    fetchTasks().then((data) => {
      const rawEvents = groupBy(data, (item) => DateTime.fromMillis(item.createdAt).toLocaleString(DateTime.DATE_SHOR));
      const finalTasks = [];
      const todaysGroup = DateTime.now().toLocaleString(DateTime.DATE_SHORT);
      let foundTodaysGroup = false
      Object.keys(rawEvents).forEach((group) => {
      if (group === todaysGroup) {
        foundTodaysGroup = true;
      }
      finalTasks.push({ header: group, tasks:  rawEvents[group] })
      });
      if (!foundTodaysGroup) {
      finalTasks.unshift({ header: todaysGroup, tasks: []})
      }
      finalTasks.sort((a, b) => {
      return DateTime.fromFormat(b.header, 'M/dd/yyyy').toMillis() - DateTime.fromFormat(a.header, 'M/dd/yyyy').toMillis();
      });
      setTodos(finalTasks);
    });

  }, [mounted]);


  const addTodo = async (item, createdAt, goal) => {
    const currentTodos = cloneDeep(todos);
    const newItem = {
      createdAt,
      title: item,
      id: v4()
    }
    if (goal) {
      newItem.goal = goal;
    }
    const dateForCreatedTask = DateTime.fromMillis(createdAt).toLocaleString(DateTime.DATE_SHORT);
    const correctDay = currentTodos.find((day) => day.header === dateForCreatedTask)
    if (correctDay) {
      correctDay.tasks = [newItem, ...correctDay.tasks];
    } else {
      currentTodos.unshift({header: dateForCreatedTask, tasks: [newItem]});
    }
    setTodos(currentTodos);
    await submitCreateTask(newItem);
  };

  const completeTask = async (task) => {
    const completedTask = {
      completedAt: new Date().getTime(),
      ...task
    };
    const currentTodos = cloneDeep(todos);
    const headerKey = DateTime.fromMillis(completedTask.createdAt).toLocaleString(DateTime.DATE_SHORT);
    const todoSet = currentTodos.find((item) => item.header === headerKey);
    const thisTask = todoSet.tasks.find((currentTask) => currentTask.id === task.id);

    thisTask.completed_at = completedTask.completedAt;
    setTodos(currentTodos);
    submitCompleteTask(completedTask);
  }
  return (
    <div className="App">
        <TodoAdder addTodo={addTodo}/>
        <TodoShower todos={todos} completeTask={completeTask} />
    </div>
  );
}

export default App;
