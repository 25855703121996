import { useEffect, useRef, useState } from "react";
import { v4 } from "uuid";
import { GoalAdder } from "./goal-adder";
import './goals.css';

async function getGoals() {
    const response = await fetch(
      `https://evvki1qmnl.execute-api.us-east-1.amazonaws.com/goals`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  }
async function addGoal(name, description, target_date) {
    const response = await fetch(
        `https://evvki1qmnl.execute-api.us-east-1.amazonaws.com/goals`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name, description, id: v4(), created_date: new Date().getTime(), target_date })
        }
      );
      return await response.json();
}

export const GoalsPage = () => {
    const mounted = useRef(false);
    const [goals, setGoals] = useState([]);
    useEffect(() => {
        getGoals().then((goals) => {
            setGoals(goals);
        })
    }, [mounted]);
    return (
        <div>
            <div className="goal-shower">
                {goals.map((goal) => {
                    return (
                        <div key={goal.id}>{goal.name}</div>
                    )
                })}
            </div>
            <GoalAdder addGoal={addGoal}/>
        </div>
    )
}
