import { useState } from "react"
import { DateTime } from "luxon";

export const GoalAdder = ({ addGoal }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [targetDate, setTargetDate] = useState();
    return (
        <div className="goal-adder">
            <input type="text" value={name} placeholder="Goal Name" onChange={(e) => setName(e.target.value)}></input>
            <textarea type="text" value={description} placeholder="Goal Description" onChange={(e) => setDescription(e.target.value)}></textarea>
            <input type="date" onChange={(e) => setTargetDate(e.target.value)}></input>
            <button onClick={() => { addGoal(name, description, DateTime.fromISO(targetDate).toMillis()) }}>Add Goal</button>
        </div>
    )
}
